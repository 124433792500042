<template lang="html">
  <div id="thank-you-page">

    <section class="container main-section">
      <div class="row">
        <div class="col-12 col-info">
          <p>
            <img class="logo" src="public/images/logo.png">
          </p>

          <h4 class="mt-4 mb-3 title">Gracias por contactarnos</h4>

          <p>
            Aquí va un contenido de texto corto para completar
          </p>

          <div class="mt-2 mt-lg-3">
						<a class="t-150 btn-network" target="_blank" :href="$root.redes.facebook">
							<i class="fab fa-facebook-f"></i>
						</a>
						<a class="t-150 btn-network" target="_blank" :href="$root.redes.instagram">
							<i class="fab fa-instagram"></i>
						</a>
						<a class="t-150 btn-network" target="_blank" :href="'https://wa.me/'+$root.redes.whatsapp">
							<i class="fab fa-whatsapp"></i>
						</a>
					</div>

          <p class="mt-5">
            <router-link class="btn btn-s1" to="/"><i class="fas fa-home"></i> Ir al Inicio</router-link>
          </p>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
