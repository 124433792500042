<template lang="html">
  <section class="placed-backg extra-info-component">
    <div class="container oversized-container">
      <div class="row">
        <div class="col-md-4 col-info">
          <p class="txt-icon"><i class="far fa-phone-alt"></i></p>
          <h3 class="title">Soporte y asesoría</h3>
        </div>

        <div class="col-md-4 col-info">
          <p class="txt-icon"><i class="far fa-star"></i></p>
          <h3 class="title">Productos de alta calidad</h3>
        </div>

        <div class="col-md-4 col-info">
          <p class="txt-icon"><i class="far fa-clipboard-list"></i></p>
          <h3 class="title">Seguimiento a ventas</h3>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
}
</script>
