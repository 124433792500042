<template lang="html">
    <b-form id="products-results-page" @submit="onSubmit">
  
      <section class="products-section">
        <div class="container oversized-container">
          <div class="row">
            <div class="col-12 box-breadcrumb-s1">
              <span>Home</span>
              <span>Blogs</span>
            </div>
          </div>
  
          <div class="row">

            <!-- Col products -->
            <div class="col-lg col-results">
              <div class="row box-top">
                <div class="col-lg-12 col-title">
                  <h2 class="title-s1">Blogs</h2>
                </div>
              </div>
  
              <div class="row">
                <div class="col-sm-6 col-lg-6 col-xl-4 box-product-sample-s1" v-for="(p, pInx) in blogs" :key="'pInx-'+pInx">
                  <router-link class="box-product-s1" :to="'/blogs/'+p.id">
                    <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url(' + p.imageUrl + ')' }">
                      <img class="empty" src="public/images/shared/empty-1.png">
                    </div>
  
                    <div class="box-info">
                      <h5 class="name">{{ p.title }}</h5>
                    
                    </div>
                  </router-link>
                </div>
              </div>
  
            </div>
            <!--  -->
  
          </div>
        </div>
      </section>
  
    </b-form>
  </template>
  
  <script>
  export default {
    data() {
      return {
        blogs: [],
      }
    },

    methods: {
        onSubmit(){

        },
        getBlogs: function(){
            axios.post(tools.url('/api/blogs'),this.formSearch).then(({data}) =>{
                this.blogs = data;
            });
        },
    },
  
    mounted() {
      this.getBlogs();
      
    }
  }
  </script>
  