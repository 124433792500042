<template lang="html">
  <b-form id="products-results-page" @submit="onSubmit">

    <section class="products-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-12 box-breadcrumb-s1">
            <span>Home</span>
            <span>Productos</span>
            <router-link to="">Destacados</router-link>
          </div>
        </div>

        <div class="row">
          <!-- Mobile filters -->
          <div class="col-12 col-filters-mobile">
            <div class="bg-filter-mv" v-if="showFilters == true" @click="showFilters = false"></div>
            <a class="btn-filters" @click="showFilters = !showFilters">
              <i class="fas fa-filter"></i><span>Ver filtros</span>
            </a>
          </div>
          <!--  -->

          <!-- Col filters -->
          <div class="col-lg col-filters" v-bind:class="{ 'show-filters': showFilters == true }">
            <h4 class="d-lg-none _title">
              <span><strong>Filtrar</strong></span>
              <a class="btn-hide" @click="showFilters = false"><i class="fas fa-chevron-left"></i></a>
            </h4>

            <div class="filters-container">
              <div class="box-filters">
                <h6 class="group-f-title">Categoría</h6>

                <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    v-model="formSearch.category"
                    :aria-describedby="ariaDescribedby"
                    name="fil-1">
                    <b-form-checkbox
                      :value="c.id"
                      v-for="(c, cInx) in categoriesOpcs"
                      :key="'cInx-'+cInx">
                      {{ c.name }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </div>

              <div class="box-filters">
                <h6 class="group-f-title">Subcategoría</h6>

                <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    v-model="formSearch.subcategory"
                    :aria-describedby="ariaDescribedby"
                    name="fil-11">
                    <b-form-checkbox
                      :value="s.id"
                      v-for="(s, cInx) in subcategoriesOpcs"
                      :key="'sInx-'+cInx">
                      {{ s.name }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </div>

              <div class="box-filters">
                <h6 class="group-f-title">Material</h6>

                <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    v-model="formSearch.material"
                    :aria-describedby="ariaDescribedby"
                    name="fil-2">
                    <b-form-checkbox
                      :value="b.id"
                      v-for="(b, bInx) in materialsOpcs"
                      :key="'bInx-'+bInx">
                      {{ b.name }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </div>

              <div class="box-filters">
                <h6 class="group-f-title">Precio</h6>

                <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group
                    v-model="formSearch.price"
                    :aria-describedby="ariaDescribedby"
                    name="fil-3">
                    <b-form-radio
                      :value="p.id"
                      v-for="(p, pInx) in price"
                      :key="'pInx-'+pInx">
                      {{ p.name }}
                    </b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </div>

              <!-- <div class="box-filters">
                <h6 class="group-f-title">Descuentos</h6>

                <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group
                    v-model="formSearch.discount"
                    :aria-describedby="ariaDescribedby"
                    name="fil-4">
                    <b-form-radio
                      :value="d.id"
                      v-for="(d, dInx) in discounts"
                      :key="'dInx-'+dInx">
                      {{ d.name }}
                    </b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </div> -->

            </div>
          </div>
          <!--  -->

          <!-- Col products -->
          <div class="col-lg col-results">
            <div class="row box-top">
              <div class="col-lg-7 col-title">
                <h2 class="title-s1">Productos destacados</h2>
              </div>

              <div class="col-lg-5 col-order">
                <span class="label">Ordenar por</span>
                <b-form-select v-model="formSearch.order">
                  <b-form-select-option value="1">Nombre (ascendente)</b-form-select-option>
                  <b-form-select-option value="2">Mayor precio</b-form-select-option>
                  <b-form-select-option value="3">Menor precio</b-form-select-option>
                  
                </b-form-select>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6 col-lg-6 col-xl-4 box-product-sample-s1" v-for="(p, pInx) in products" :key="'pInx-'+pInx">
                <router-link class="box-product-s1" :to="'/producto/'+$root._converToURL(p.name,p.id)">
                  <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url(' + p.imageUrl + ')' }">
                    <img class="empty" src="public/images/shared/empty-1.png">
                  </div>

                  <div class="box-info">
                    <h5 class="name">{{ p.name }}</h5>
                    <h6 class="category">{{ p.category ? p.category.name : null}}</h6>

                    <div v-if="p.price_org != null">
                      
                      <h6 style="text-decoration: line-through;color: #ababab;" class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(p.price_org) }}</h6>
                      <h6  class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(p.price) }}</h6>
                    </div>
                    <div v-else>
                      <h6  class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(p.price) }}</h6>
                    </div>
                    
                  </div>
                </router-link>
              </div>
            </div>

            <div class="row mt-4" v-if="pagination.total_products > 6">
              <div class="col-12 text-center">
                <div class="d-inline-block mx-0 col-pagination-sample-1">
                  <b-pagination-nav :link-gen="linkGen" :number-of-pages="pagination.total" use-router></b-pagination-nav>
                </div>
              </div>
            </div>
          </div>
          <!--  -->

        </div>
      </div>
    </section>

  </b-form>
</template>

<script>
export default {
  data() {
    return {
      showFilters: false,

      categoriesOpcs: [],
      subcategoriesOpcs:[],
      materialsOpcs: [],

      price: [
        { id: 1, name: '$0 - $199' },
        { id: 2, name: '$200 - $599' },
        { id: 3, name: '$600 - $999' },
        { id: 4, name: '$1,000 - $1,999' },
        { id: 5, name: '$2,000 - $2,999' },
        { id: 6, name: '$3,000 o más' },
      ],

      discounts: [
        { id: 1, name: '10%' },
        { id: 2, name: '20%' },
        { id: 3, name: '30%' },
      ],

      formSearch: {
        keywords: null,
        order: 1,
        category: [],
        subcategory: [],
        material:[],
        price: null,
        discount: null,
      },

      products: [],

      pagination:{
        currentpage: 1,
        total: 1,
        total_products: 16,
        number: 10
      },
    }
  },

  watch:{
        '$route':function(){
            if(this.$route.query.page){
              this.pagination.currentpage = this.$route.query.page;
            }
            else{
                this.pagination.currentpage = 1;
            }
            if(this.$route.query.category){
                var categories_id = this.$route.query.category.split(',');
                this.formSearch.category = categories_id;
                this.getSubcategories();
            }
            if (this.$route.query.subcategory) {
                var subcategories_id = this.$route.query.subcategory.split(',');
                this.formSearch.subcategory = subcategories_id;
            }
            if (this.$route.query.material) {
                var materials_id = this.$route.query.material.split(',');
                this.formSearch.material = materials_id;
            }
            if (this.$route.query.keywords) {
                this.formSearch.keywords = this.$route.query.keywords;
            }
            if (this.$route.query.price) {
                this.formSearch.price = this.$route.query.price;
            }
            if (this.$route.query.order) {
                this.formSearch.order = this.$route.query.order;
            }
            this.getProducts();
           
        } ,
        formSearch: {
            handler(val){
                this.$router.push({ path: '/productos', query: { 
                                  category: this.formSearch.category.join(','),
                                  subcategory:this.formSearch.subcategory.join(','),
                                  material:this.formSearch.material.join(','),
                                  keywords:this.formSearch.keywords,
                                  price:this.formSearch.price,
                                  order:this.formSearch.order,
                                  page:this.pagination.currentpage } });
            },
            deep: true
        }
    },

  methods: {
      linkGen(pageNum) {
        

        return {
            path: '/productos',
            query: { 
              category:this.formSearch.category.join(','),
              subcategory:this.formSearch.subcategory.join(','),
              material:this.formSearch.material.join(','),
              price:this.formSearch.price,
              order:this.formSearch.order,
              keywords:this.formSearch.keywords,
              page: pageNum }
        }
      },

      onSubmit(event) {
        event.preventDefault();
      },

      getCategories(){
				axios.get(tools.url("/api/categories")).then((response)=>{
			    	this.categoriesOpcs = response.data;
			    }).catch((error)=>{
			    	this.$parent.handleErrors(error);
			    });
			},

			getSubcategories(){
				
					axios.post(tools.url("/api/subcategories"),{ids:this.formSearch.category}).then((response)=>{
						this.subcategoriesOpcs = response.data;
					}).catch((error)=>{
			    		this.$parent.handleErrors(error);
			    	});
				
			},

			getMaterials(){
				axios.get(tools.url("/api/materials")).then((response)=>{
			    	this.materialsOpcs = response.data;
			    }).catch((error)=>{
			    	this.$parent.handleErrors(error);
			    });
			},


      getProducts: function(){
            this.loading = true;
            axios.post(tools.url('/api/products?page=' + this.pagination.currentpage),this.formSearch).then(({data}) =>{
                this.products = data.data;
                this.pagination.total = data.last_page;
                this.pagination.total_agents = data.total;
                this.loading = false;
            });
      },
  },

  mounted() {
    this.getCategories();
    this.getMaterials();
      if(this.$route.query.category){
          var categories_id = this.$route.query.category.split(',');
          this.formSearch.category = categories_id;
          this.getSubcategories();
      }
      if (this.$route.query.subcategory) {
          var subcategories_id = this.$route.query.subcategory.split(',');
          this.formSearch.subcategory = subcategories_id;
      
      }
      if (this.$route.query.material) {
          var materials_id = this.$route.query.material.split(',');
          this.formSearch.material = materials_id;
      
      }
      if (this.$route.query.keywords) {
          this.formSearch.keywords = this.$route.query.keywords;
      }
      if (this.$route.query.keywords) {
          this.formSearch.keywords = this.$route.query.keywords;
      }
      if (this.$route.query.price) {
          this.formSearch.price = this.$route.query.price;
      }
      if (this.$route.query.order) {
          this.formSearch.order = this.$route.query.order;
      }

      this.getProducts();
  }
}
</script>
