var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"company-page"}},[_vm._m(0),_vm._v(" "),_c('section',{staticClass:"container extra-info-section"},[_c('div',{staticClass:"row row-info"},[_c('div',{staticClass:"col-10 col-sm-8 col-md-5 col-lg-3 col-image"},[_c('div',{staticClass:"placed-backg circle",style:({ backgroundImage: 'url(public/images/pages/company/img-2.jpg)' })},[_c('img',{attrs:{"src":"public/images/shared/empty-1.png"}})])]),_vm._v(" "),_vm._m(1)]),_vm._v(" "),_c('div',{staticClass:"row mt-4 row-info"},[_vm._m(2),_vm._v(" "),_c('div',{staticClass:"col-10 col-sm-8 col-md-5 col-lg-3 order-0 order-md-1 col-image"},[_c('div',{staticClass:"placed-backg circle",style:({ backgroundImage: 'url(public/images/pages/company/img-3.jpg)' })},[_c('img',{attrs:{"src":"public/images/shared/empty-1.png"}})])])])]),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_c('section',{staticClass:"testimonials-section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_vm._m(5),_vm._v(" "),_c('div',{staticClass:"col-lg-8 col-carousel"},[_c('swiper',{staticClass:"swiper",attrs:{"options":_vm.swiperOption}},[_c('swiper-slide',[_c('div',{staticClass:"box-testimonial"},[_c('div',{staticClass:"box-photo"},[_c('div',{staticClass:"placed-backg image",style:({ backgroundImage: 'url(public/images/pages/company/img-5.jpg)' })})]),_vm._v(" "),_c('div',{staticClass:"box-info"},[_c('h5',{staticClass:"name"},[_vm._v("Yosse García")]),_vm._v(" "),_c('p',{staticClass:"descr"},[_vm._v("\n                    “Muy buena experiencia vendiendo la Chapa, encantada bastante. Súper recomendado.”\n                  ")])])])]),_vm._v(" "),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_vm._v(" "),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],1)],1)])])]),_vm._v(" "),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"basic-info-section"},[_c('div',{staticClass:"bg-image"}),_vm._v(" "),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"offset-md-5 col-md-7 offset-lg-6 col-lg-6 col-info"},[_c('h5',{staticClass:"mb-2 title"},[_vm._v("Nosotros")]),_vm._v(" "),_c('p',{staticClass:"p-lg"},[_vm._v("\n            Somos una empresa Mexicana con mas de 10 años de experiencia y clara Misión de ayudar a las mujeres emprendedoras a generar ingresos adicionales a través de la venta de joyería\n          ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-7 col-lg-6 mr-lg-2 mr-lg-5 col-text"},[_c('h2',{staticClass:"title"},[_vm._v("EN UNYX")]),_vm._v(" "),_c('p',[_vm._v("\n          Nos esforzamos cada día por ofrecerte el mejor servicio y una atención personalizada.\n        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-7 col-lg-6 text-right ml-lg-5 order-1 order-md-0 col-text"},[_c('p',[_vm._v("\n          Trabajamos todo el tiempo en poder ayudarte a que realices de una manera súper sencilla tú negocio de joyería.\n        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"benefits-section"},[_c('div',{staticClass:"container"},[_c('h2',{staticClass:"title"},[_vm._v("Beneficios de ser distribuidora UNYX")]),_vm._v(" "),_c('div',{staticClass:"d-block list-b"},[_c('p',{staticClass:"mb-3"},[_c('i',{staticClass:"fas fa-check-circle ic"}),_vm._v(" Capacitaciones de venta por zoom.\n        ")]),_vm._v(" "),_c('p',{staticClass:"mb-3"},[_c('i',{staticClass:"fas fa-check-circle ic"}),_vm._v(" Transmisiones en vivo cada semana, para que conozcas y apartes tus productos.\n        ")]),_vm._v(" "),_c('p',{staticClass:"mb-3"},[_c('i',{staticClass:"fas fa-check-circle ic"}),_vm._v(" Catálogos en línea cada semana.\n        ")]),_vm._v(" "),_c('p',{staticClass:"mb-3"},[_c('i',{staticClass:"fas fa-check-circle ic"}),_vm._v(" Programa de recompensas.\n        ")]),_vm._v(" "),_c('p',[_c('i',{staticClass:"fas fa-check-circle ic"}),_vm._v(" Atención personalizada.\n        ")])]),_vm._v(" "),_c('p',{staticClass:"mt-5"},[_c('a',{staticClass:"t-150 btn-contact",attrs:{"href":"#"}},[_vm._v("Solicita catálogos")])]),_vm._v(" "),_c('h5',{staticClass:"mt-4"},[_vm._v("Para conocer más acerca de nosotros, te invitamos a nuestro Facebook Live todos los jueves a las 18:00 hrs.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"trainings-section"},[_c('div',{staticClass:"bg-image"}),_vm._v(" "),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-7 col-lg-6 col-info"},[_c('h5',{staticClass:"mb-2 title"},[_vm._v("Capacitaciones en línea")]),_vm._v(" "),_c('p',{staticClass:"mb-3 p-lg"},[_vm._v("\n            Una de las ventajas de formar parte de Unyx es que te ayudamos a prepararte como mujer emprendedora con nuestras Capacitaciones por Zoom.\n          ")]),_vm._v(" "),_c('p',{staticClass:"p-lg"},[_vm._v("\n            Cada mes contamos con un taller diferente, para apoyarte a desarrollar tus habilidades en la venta de joyería y de esta forma sacar el máximo provecho en tu inversión.\n          ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('h5',[_vm._v("Algunas de nuestras capacitaciones son:")]),_vm._v(" "),_c('ul',{staticClass:"mt-1"},[_c('li',[_vm._v("Neuroventas")]),_vm._v(" "),_c('li',[_vm._v("Speech de ventas")]),_vm._v(" "),_c('li',[_vm._v("Manejo de redes sociales")]),_vm._v(" "),_c('li',[_vm._v("Fotografía con celular")]),_vm._v(" "),_c('li',[_vm._v("Tus emociones te empoderan o te limitan")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-8 col-title"},[_c('h2',{staticClass:"title"},[_vm._v("Testimoniales")]),_vm._v(" "),_c('h5',{staticClass:"subtitle"},[_vm._v("\n            Te mostramos algunas de las opiniones de nuestras distribuidoras, que ya forman parte de la gran familia UNYX.\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"thanks-section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-8"},[_c('h2',{staticClass:"title"},[_vm._v("¡GRACIAS POR SU CONFIANZA!")]),_vm._v(" "),_c('h5',{staticClass:"subtitle"},[_vm._v("POR MÁS DE 10 AÑOS NOS HAN PERMITIDO ACOMPAÑAR LA VIDA DE MUCHAS EMPRENDEDORAS, QUE COMO TÚ ESTÁN DISPUESTAS A CUMPLIR SUS OBJETIVOS.")])])])])])
}]

export { render, staticRenderFns }