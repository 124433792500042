<template lang="html">
  <div id="home-page">

    <section class="banner-section">
      <swiper class="swiper desktop" :options="bannersOptions">
        <swiper-slide v-for="(b, bhInx) in banners.pc" :key="'bhInx-'+bhInx">
          <div class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+b.imageUrl+')' }">
            <img src="public/images/pages/home/banner.png">
          </div>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>

      <swiper class="swiper mobile" :options="bannersOptions">
        <swiper-slide v-for="(b, bhmInx) in banners.movil" :key="'bhmInx-'+bhmInx">
          <div class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+b.imageUrl+')' }">
            <img src="public/images/pages/home/banner-m.png">
          </div>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>

    <section class="container oversized-container products-section">
      <div class="box-title">
        <h4 class="title-s1">Explora nuestro catálogo</h4>
      </div>

      <div class="box-products">
        <swiper class="swiper" :options="catalogOptions">
          <swiper-slide v-for="(c, cInx) in categories" :key="'cInx-'+cInx">
            <router-link class="placed-backg box-catalog-s1" v-bind:style="{ backgroundImage: 'url('+c.imageUrl+')' }" :to="'/productos/?category='+c.id">
              <img class="empty" src="public/images/shared/empty-1.png">

              <div class="box-name">
                <h5 class="name">{{ c.name }}</h5>
              </div>
            </router-link>
          </swiper-slide>

          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
    </section>

    <section class="container oversized-container products-section">
      <div class="box-title">
        <h4 class="title-s1">Productos destacados</h4>
      </div>

      <div class="box-products">
        <swiper class="swiper s2" :options="catalogOptions">
          <swiper-slide v-for="(p, pInx) in products" :key="'pInx-'+pInx">
            <div class="col-12 px-0 box-product-sample-s1">
              <router-link class="px-mob box-product-s1" :to="'/producto/'+$root._converToURL(p.name,p.id)">
                <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url(' + p.imageUrl + ')' }">
                  <img class="empty" src="public/images/shared/empty-1.png">
                </div>

                <div class="box-info">
                  <h5 class="name">{{ p.name }}</h5>
                  <h6 class="category">{{ p.category ? p.category.name : null}}</h6>
                  <div class="col col-price" v-if="p.price_org != null">
                  <p style="text-decoration: line-through;color: #ababab;">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(p.price_org) }}</p>
                  <p>$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(p.price) }}</p>
                </div>
                <div class="col col-price" v-else>
                    <p>$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(p.price) }}</p>
                </div>
                </div>
              </router-link>
            </div>
          </swiper-slide>

          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
    </section>

    <ExtraInfoComponent></ExtraInfoComponent>

  </div>
</template>

<script>
import ExtraInfoComponent from './extra-info-component.vue';
export default {
  components: {
    ExtraInfoComponent
  },

  data() {
    return {
      banners: {},
      categories:[],
      products:[],
      

      // == Carousel options ==
      bannersOptions: {
        loop: true,
        speed: 700,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },
      },

      catalogOptions: {
        slidesPerView: 4,
        spaceBetween: 25,
        loop: true,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          1200: {
            slidesPerView: 4,
          },
          992: {
            slidesPerView: 3,
          },
          576: {
            slidesPerView: 2,
          },
          1: {
            slidesPerView: 1,
          },
        }
      }
      // == ==
    }
  },

  methods: {
    getHome: function(){
        axios.get(tools.url('/api/home')).then(({data}) =>{
            this.banners = data.banners;
            this.products = data.products;
            this.categories = data.categories;
        });
    },
  },
  mounted(){
    this.getHome();
  }
}
</script>

