<template lang="html">
    <div id="article-page">
  
    
      <section class="container article-content-section">
        <div class="row">
          <div class="col-12 col-top">
            <h1 class="mb-2 title">{{blog.title}}</h1>
            <h5 class="by-n-date"> {{blog.created}}</h5>
          </div>
  
          <div class="col-12 col-image">

            <div  class="placed-backg image" v-bind:style="{ backgroundImage: 'url('+ blog.imageUrl +')' }">
              <img src="public/images/pages/blog/image.png">
            </div>
          </div>
  

          <div class="col-12 col-content" v-html="blog.body" ></div>
          
        </div>
      </section>
  
    </div>
  </template>
  
  
  <script>
  export default {
    data() {
      return {
  
        blog:{},
        id:null
        // == ==
      }
    },
    methods: {
      getBlog() { // Obtener la info de la BD
        axios.get(tools.url("/api/blogs/"+this.id)).then((response)=>{
          this.blog = response.data;
        });
      },
    },
   
    mounted(){
      if(this.$route.params.id){
        this.id = this.$root._getURLID(this.$route.params.id);
          this.getBlog();
      }
    },
  }
  </script>
  
  <style>
  .col-content img{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  </style>