<template lang="html">
  <div id="cart-page" class="d-flex account-page">
    <div class="container main-box">

      <div class="row">
        <div class="col-12 px-2">
          <h1 class="mb-3 page-title">Mis favoritos</h1>
        </div>

        <div class="col-lg px-2 col-menu">
          <div class="white-box">
            <div class="box-menu py-2">
              <p class="item">
                <router-link class="btn-menu" to="/cart"><i class="fas fa-shopping-cart"></i> Mi carrito</router-link>
              </p>
              <p class="item">
                <router-link class="btn-menu" to="/favoritos"><i class="fas fa-heart"></i> Mis favoritos</router-link>
              </p>
              <!-- <p class="item">
                <router-link class="btn-menu" to="/historial-de-compras"><i class="fas fa-history"></i> Historial de compras</router-link>
              </p> -->
            </div>
          </div>
        </div>

        <div class="col-lg px-2 col-cart-data">

          <!-- <div class="white-box"> -->
          <b-form class="white-box" @submit="">

            <!-- Paso 1 (Lista de productos agregados a carrito) -->
            <div class="col-12 pt-3 product-list-table" v-if="$route.params.step !== 'envio' && $route.params.step !== 'pago'">
              <div class="col-12 px-0">
                <h5>Productos agregados a favoritos</h5>

                <h6 class="mt-4 pb-4 f-w-400 text-warning" v-if="!products.length"><i class="fas fa-shopping-basket"></i> Ningun producto ha sido agregado</h6>
              </div>

              <div class="row row-products" v-for="(product, index) in products">
                <div class="col col-image">
                  <img :src="product.imageUrl">
                </div>

                <div class="col col-info">
                  <h5 class="txt-name">
                    <router-link target="_blank" :to="'/producto/'+product.id">{{ product.name }}</router-link>
                  </h5>

                  <div class="row mx-0 align-items-center">
                    <div class="d-flex">
                      <h6 class="txt-sm text-muted" style="font-size: 0.90rem;">SKU: <strong>{{product.sku}}</strong></h6>
                    </div>
                    <div class="d-flex">
                      <div class="box-color-opt opt-sm">
                        <a class="color" title="Color x" v-bind:style="{ backgroundColor: '#80c65d' }"></a>
                      </div>
                    </div>
                  </div>
                  <hr class="mt-0 mb-1" />

                  <router-link class="link-sm text-muted" :to="'/producto/'+product.id"><i class="far fa-shopping-cart"></i> Ver producto</router-link> 
                  <span class="mx-2">|</span> <a class="link-sm text-success" @click="addCart(index)"><i class="fas fa-cart-plus"></i> Agregar</a> 
                  
                  <span class="mx-2">|</span> <a class="link-sm text-danger" @click="removeCart(index)"><i class="far fa-trash-alt"></i> Quitar</a>
                </div>

                <div class="col text-center col-price">
                  <p>$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }}</p>
                </div>
              </div>
            </div>
            <!-- paso 1, Fin -->


          </b-form>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
        products: []
    }
  },
  methods: {

    getRows: function(){
      var storage = localStorage.getItem('favorites');
      if(storage != null){
        var products = JSON.parse(storage);
        axios.post(tools.url('/api/favorites'),{ products:products }).then((response)=>{
          this.products = response.data;
          
        }).catch((error)=>{

        });
      }
    },
    removeCart(index) {
        var cart = [];
        for (let z = 0; z < this.products.length; z++) {
            if(z != index){
                cart.push(this.products);
            }
        }
        this.products = cart;

        localStorage.setItem("favorites",JSON.stringify(this.products));
    },
    addCart(index){
        
        var cart = [];
        var encontrado = false;
        if(localStorage.cart){
              cart = JSON.parse(localStorage.getItem('cart'));
              for(var i = 0; i < cart.length ; i++){
                  if(cart[i].id == this.products[index]['id'] ){
                      var suma = cart[i].quantity + 1;
                      cart[i].quantity = suma;
                      encontrado = true;
                      break;
                  }
              }
              if(!encontrado){
                  cart.push({ id:this.products[index]['id'], quantity:1 });
              }
        }
        else{
            cart.push({ id:this.products[index]['id'], quantity:1 });
        }

        localStorage.setItem("cart",JSON.stringify(cart));
        this.$root.cartCount = cart.length;

        this.removeCart(index);
        this.$router.push('/cart');
    }
  },
  mounted(){
      this.getRows();
  }
}
</script>
