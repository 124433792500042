<template lang="html">
  <div id="company-page">
    <section class="basic-info-section">
      <div class="bg-image"></div>

      <div class="container">
        <div class="row">
          <div class="offset-md-5 col-md-7 offset-lg-6 col-lg-6 col-info">
            <h5 class="mb-2 title">Nosotros</h5>

            <p class="p-lg">
              Somos una empresa Mexicana con mas de 10 años de experiencia y clara Misión de ayudar a las mujeres emprendedoras a generar ingresos adicionales a través de la venta de joyería
            </p>
          </div>

          <!-- <div class="col-lg-6"></div> -->
        </div>
      </div>
    </section>

    <section class="container extra-info-section">
      <div class="row row-info">
        <div class="col-10 col-sm-8 col-md-5 col-lg-3 col-image">
          <div class="placed-backg circle" v-bind:style="{ backgroundImage: 'url(public/images/pages/company/img-2.jpg)' }">
            <img src="public/images/shared/empty-1.png">
          </div>
        </div>

        <div class="col-md-7 col-lg-6 mr-lg-2 mr-lg-5 col-text">
          <h2 class="title">EN UNYX</h2>
          <p>
            Nos esforzamos cada día por ofrecerte el mejor servicio y una atención personalizada.
          </p>
        </div>
      </div>

      <div class="row mt-4 row-info">
        <div class="col-md-7 col-lg-6 text-right ml-lg-5 order-1 order-md-0 col-text">
          <p>
            Trabajamos todo el tiempo en poder ayudarte a que realices de una manera súper sencilla tú negocio de joyería.
          </p>
        </div>

        <div class="col-10 col-sm-8 col-md-5 col-lg-3 order-0 order-md-1 col-image">
          <div class="placed-backg circle" v-bind:style="{ backgroundImage: 'url(public/images/pages/company/img-3.jpg)' }">
            <img src="public/images/shared/empty-1.png">
          </div>
        </div>
      </div>
    </section>

    <section class="benefits-section">
      <div class="container">
        <h2 class="title">Beneficios de ser distribuidora UNYX</h2>

        <div class="d-block list-b">
          <p class="mb-3">
            <i class="fas fa-check-circle ic"></i> Capacitaciones de venta por zoom.
          </p>
          <p class="mb-3">
            <i class="fas fa-check-circle ic"></i> Transmisiones en vivo cada semana, para que conozcas y apartes tus productos.
          </p>
          <p class="mb-3">
            <i class="fas fa-check-circle ic"></i> Catálogos en línea cada semana.
          </p>
          <p class="mb-3">
            <i class="fas fa-check-circle ic"></i> Programa de recompensas.
          </p>
          <p>
            <i class="fas fa-check-circle ic"></i> Atención personalizada.
          </p>
        </div>

        <p class="mt-5">
          <a class="t-150 btn-contact" href="#">Solicita catálogos</a>
        </p>

        <h5 class="mt-4">Para conocer más acerca de nosotros, te invitamos a nuestro Facebook Live todos los jueves a las 18:00 hrs.</h5>
      </div>
    </section>

    <!-- <ExtraInfoComponent></ExtraInfoComponent> -->

    <section class="trainings-section">
      <div class="bg-image"></div>

      <div class="container">
        <div class="row">
          <div class="col-md-7 col-lg-6 col-info">
            <h5 class="mb-2 title">Capacitaciones en línea</h5>

            <p class="mb-3 p-lg">
              Una de las ventajas de formar parte de Unyx es que te ayudamos a prepararte como mujer emprendedora con nuestras Capacitaciones por Zoom.
            </p>
            <p class="p-lg">
              Cada mes contamos con un taller diferente, para apoyarte a desarrollar tus habilidades en la venta de joyería y de esta forma sacar el máximo provecho en tu inversión.
            </p>

            <br />
            <h5>Algunas de nuestras capacitaciones son:</h5>
            <ul class="mt-1">
              <li>Neuroventas</li>
              <li>Speech de ventas</li>
              <li>Manejo de redes sociales</li>
              <li>Fotografía con celular</li>
              <li>Tus emociones te empoderan o te limitan</li>
            </ul>
          </div>

          <!-- <div class="col-lg-6"></div> -->
        </div>
      </div>
    </section>

    <section class="testimonials-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8 col-title">
            <h2 class="title">Testimoniales</h2>
            <h5 class="subtitle">
              Te mostramos algunas de las opiniones de nuestras distribuidoras, que ya forman parte de la gran familia UNYX.
            </h5>
          </div>

          <div class="col-lg-8 col-carousel">
            <swiper class="swiper" :options="swiperOption">
              <swiper-slide>
                <div class="box-testimonial">
                  <div class="box-photo">
                    <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url(public/images/pages/company/img-5.jpg)' }"></div>
                  </div>

                  <div class="box-info">
                    <h5 class="name">Yosse García</h5>

                    <p class="descr">
                      “Muy buena experiencia vendiendo la Chapa, encantada bastante. Súper recomendado.”
                    </p>
                  </div>
                </div>
              </swiper-slide>

              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>
        </div>
      </div>
    </section>

    <section class="thanks-section">
      <div class="container">
        <div class="row justify-content-center">

          <div class="col-lg-8">
            <h2 class="title">¡GRACIAS POR SU CONFIANZA!</h2>

            <h5 class="subtitle">POR MÁS DE 10 AÑOS NOS HAN PERMITIDO ACOMPAÑAR LA VIDA DE MUCHAS EMPRENDEDORAS, QUE COMO TÚ ESTÁN DISPUESTAS A CUMPLIR SUS OBJETIVOS.</h5>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import ExtraInfoComponent from '../home/extra-info-component.vue';
export default {
  components: {
    ExtraInfoComponent
  },

  data() {
    return {

      // == Carousel options ==
      swiperOption: {
        loop: true,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
      // == ==
    }
  }
}
</script>
