<template lang="html">
   <div class="container" id="text-page" v-html="info.body"></div>
  <!-- <div class="container" id="text-page">
    <h1 class="page-title txt-black">Política de envíos</h1>

    <h6>CONTAMOS CON ENVÍOS A TODA LA REPÚBLICA MEXICANA</h6>
    <p class="mb-2">
      Nuestro servicio de envío es a través de Fedex, Estafeta.
    </p>

    <br />
    <p>
       Opciones de envió:
    </p>
    <ul>
      <li>Envío $159 en la compra hasta $2999.00 en Productos. En caso de aplicar un extra a pagar por ser zona extendida, el cliente debe pagar la diferencia que marque la paquetería.</li>
      <li>Envió $119 en la compra de $3000.00 hasta $4999.00. En caso de aplicar un extra a pagar por ser zona extendida, el cliente debe pagar la diferencia que marque la paquetería.</li>
      <li>Envió $GRATIS en una Compra mayor a $5000.00</li>
      <li>Entregas Express de 1 a 3 días hábiles en todo México.</li>
      <li>Para mayor información llama al Nuestro Centro de Atención</li>
    </ul>

    <p>
      <strong>222 5000 261</strong> de Lunes a Viernes  de 10:00 am a 19:00 hrs.
    </p>

    <br />
    <p>
      Sábado de 10:00 am a 16 hrs.
    </p>
  </div> -->
</template>

<script>
export default {
  data() {
    return {
      info: {},
    }
  },
  methods: {
    getData: function(){
        axios.get(tools.url('/api/politicaenvio')).then(({data}) =>{
            this.info = data;
        });
    },
  },
  mounted(){
    this.getData();
  }
}
</script>

