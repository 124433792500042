<template lang="html">
  <div id="info-page-s1">

    <section class="container text-justify main-section">
      <div class="container" id="text-page" v-html="info.body"></div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {},
    }
  },
  methods: {
    getData: function(){
        axios.get(tools.url('/api/faqs')).then(({data}) =>{
            this.info = data;
        });
    },
  },
  mounted(){
    this.getData();
  }
}
</script>

