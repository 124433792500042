<template lang="html">
  <header id="header">
    <div class="header-top">
      <div class="container oversized-container">
        <span class="txt"><a style="color:white" :href="'https://wa.me/'+$root.redes.telefono" target="_blank"><i class="fab fa-whatsapp ic"></i> {{$root.redes.telefono}}</a></span>
        <a class="ml-4 ml-sm-5 txt" :href="'mailto:'+$root.redes.email"><i class="fal fa-envelope ic"></i> {{$root.redes.email}}</a>
      </div>
    </div>

    <div class="header-menu">
      <b-navbar toggleable="lg" type="light" variant="light">
        <div class="container oversized-container">
          <b-navbar-brand to="/">
            <img src="public/images/logo.png" alt="UNYX">
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse">
            <i class="fal fa-bars"></i>
          </b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
              <li class="nav-item simple-item">
                <a class="nav-link" @click="isPActive = !isPActive">Productos <i class="far fa-angle-down"></i></a>
              </li>
              <b-nav-item class="simple-item" to="/empresa">Empresa</b-nav-item>
              <b-nav-item class="simple-item" to="/contacto">Contacto</b-nav-item>
              <b-nav-item class="simple-item" to="/blogs">Blog</b-nav-item>
              <b-nav-item class="button-item" to="/registrarse">AFÍLIATE</b-nav-item>
            </b-navbar-nav>

            <b-navbar-nav class="ml-auto">
              <b-nav-form class="form-search" @submit="onSubmit">
                <b-button size="sm" type="submit"><i class="fal fa-search"></i></b-button>
                <b-form-input size="sm" placeholder="Buscar productos" v-model="keywords"></b-form-input>
              </b-nav-form>

              <b-nav-item class="item-w-icon b-right" to="/favoritos">
                <span class="txt-ic">
                  <i class="fal fa-heart ic"></i> <span class="txt"> Favoritos</span>
                </span>
              </b-nav-item>

              <b-nav-item class="item-w-icon b-right" to="/cart">
                <span class="txt-ic">
                  <i class="fal fa-shopping-cart ic"></i> <span class="txt"> Mi carrito</span>
                </span>
              </b-nav-item>

              <b-nav-item-dropdown class="item-w-icon" right>
                <template #button-content>
                  <span class="txt-ic">
                    <i class="fal fa-user ic"></i> <span class="txt">Mi cuenta</span>
                  </span>
                </template>
                <b-dropdown-item to="/usuario" v-if="$root.logged">Mi cuenta</b-dropdown-item>
                <b-dropdown-item to="/login" v-else>Iniciar sesión</b-dropdown-item>
                <!-- <b-dropdown-item to="/registrarse">Registrarse</b-dropdown-item> -->
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>

    <!-- Products menu -->
    <div class="t-250 products-menu" v-bind:class="{ active: isPActive }">
      <div class="menu-bg" @click="isPActive = false"></div>

      <div class="tablist" role="tablist">
        <div class="t-250 box-title" @click="isPActive = false">
          <h4>PRODUCTOS</h4>
          <br>
          <h5>Categorias</h5>
          <i class="fas fa-chevron-left icon"></i>
        </div>

        <b-card no-body class="mb-1" v-for="(category, pcatIndex) in categories" :key="pcatIndex">
          <b-card-header header-tag="header" role="tab">
            <div v-if="category.subcategories.length" class="btn-toggle">
              <router-link :to="'/productos/?category='+category.id">{{ category.name }}</router-link>
              <span class="fake-toggle" v-b-toggle="'accordion-'+pcatIndex"></span>
            </div>
            <router-link v-else class="btn-toggle-fake" :to="'/productos/?category='+category.id">{{ category.name }}</router-link>
          </b-card-header>

          <b-collapse v-if="category.subcategories.length" :id="'accordion-'+pcatIndex"  accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <ul class="ul-sub-options">
                <li v-for="subcategory in category.subcategories">
                  <router-link class="t-250" :to="'/productos/?category='+category.id+'&subcategory='+subcategory.id">{{ subcategory.name }}</router-link>
                </li>
              </ul>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
    <!--  -->
  </header>
</template>

<script>
export default {
  data() {
    return {
      isPActive: false,
      categories:[],
      keywords:null,
    }
  },

  watch: {
    $route (to, from){
      // Ocultar menu cada vez que cambie la ruta
      this.isPActive = false;
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.$router.push({ path: '/productos', query: { keywords:this.keywords} });

    },
    getCategory: function(){
        axios.get(tools.url('/api/categories')).then((response) =>{
            this.categories = response.data;

        });
    },
  },
  mounted(){
    this.getCategory();
  }
}
</script>
