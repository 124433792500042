<template lang="html">
  <footer id="footer">
  	<div class="container oversized-container">
  		<div class="row">
  			<div class="col-lg-4 col-footer col-logo">
  				<router-link to="/">
  					<img src="public/images/logo-v.png">
  				</router-link>
  			</div>

				<div class="col-sm-6 col-lg-4 col-footer">
					<p>
						<router-link class="link-s1" to="/empresa">Empresa</router-link>
					</p>
          <p class="mt-2 mt-lg-3">
						<router-link class="link-s1" to="/preguntas-frecuentes">Preguntas frecuentes</router-link>
					</p>
					<p class="mt-2 mt-lg-3">
						<router-link class="link-s1" to="/politica-de-envios">Política de envíos</router-link>
					</p>
					<p class="mt-2 mt-lg-3">
						<router-link class="link-s1" to="/politica-de-devolucion-o-reembolso">Política de devolución o reembolso</router-link>
					</p>
				</div>

				<div class="col-sm-6 col-lg-4 col-footer">
					<p>
						<router-link class="link-s1" to="/contacto">Contacto</router-link>
					</p>
					<p class="mt-2 mt-lg-3">
						<a class="txt-w-icon" target="_blank" :href="'mailto:'+$root.redes.email"><i class="far fa-envelope ic"></i> {{$root.redes.email}}</a>
					</p>
					<p class="mt-2 mt-lg-3">
						<a class="txt-w-icon" :href="'tel:'+$root.redes.telefono"><i class="far fa-phone-alt ic"></i> {{$root.redes.telefono}}</a>
					</p>
					<p class="mt-2 mt-lg-3">
						<a class="t-150 btn-network" target="_blank" :href="$root.redes.facebook">
							<i class="fab fa-facebook-f"></i>
						</a>
						<a class="t-150 btn-network" target="_blank" :href="$root.redes.instagram">
							<i class="fab fa-instagram"></i>
						</a>
						<a class="t-150 btn-network" target="_blank" :href="'https://wa.me/'+$root.redes.whatsapp">
							<i class="fab fa-whatsapp"></i>
						</a>
					</p>
				</div>

				<div class="col-12 col-footer col-copy">
					<h6>Todos los derechos reservados. © {{ currentYear }} UNYX Joyería de acero.</h6>
				</div>
			</div>
  	</div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: 0
    }
  },

  beforeMount() {
    this.currentYear = new Date().getFullYear();
  }
}
</script>
