<template lang="html">
  <div class="container" id="text-page" v-html="info.body"></div>
  <!-- <div class="container" id="text-page">
    <h1 class="page-title txt-black">Política de devolución o reembolso</h1>

    <h5>GARANTíA</h5>
    <p class="mb-2">
      Garantía, si tu pieza te llegó sin  color o brillo, la cambiamos por una nueva del mismo valor. Si tu pieza tiene algún defecto por  soldadura o montadura de piedras, tendrás 30 días naturales para solicitar un cambio.
    </p>

    <br />
    <h6>Deberás seguir estos pasos:</h6>
    <h5>EN TIENDA EN LÍNEA (CAMBIOS Y DEVOLUCIONES)</h5>
    <ol>
      <li>Notificarnos vía Llamada Telefónica a Nuestro Centro de Atención 22 22 5000 261 Deberá de realizarse dentro del periodo de 30 días naturales después de haber recibido tu producto (favor de especificar el motivo de la devolución).</li>
      <li>Nosotros te enviaremos a tu correo electrónico o número de WhatsApp una guía de paquetería y un número de autorización el cual tienes apuntar en el exterior del paquete.</li>
      <li>La mercancía No debe tener muestras de uso y debe regresarse en el mismo empaque y material con la que la recibiste.</li>
      <li>Deberás enviar la Devolución vía mensajería Estafeta o Fedex. En cuanto recibamos la mercancía devuelta y revisemos su estado y que se cumplió el procedimiento de devolución, en ese momento procederemos a realizar el cambio o reembolso.</li>
    </ol>

    <br />
    <h5>EN TIENDA FÍSICA (CAMBIOS)</h5>
    <ol>
      <li>Presentar la pieza o producto y  ticket de compra. Deberá de realizarse dentro del periodo de 30 días naturales.</li>
      <li>Podrás hacer tu cambio por una pieza del mismo o mayor precio (pagando la diferencia en tienda). No podremos hacer cambios por piezas de menor costo.</li>
    </ol>

    <br />
    <h6>* Tu cambio está sujeto a la disponibilidad en tienda.</h6>
  </div> -->
</template>

<script>
export default {
  data() {
    return {
      info: {},
    }
  },
  methods: {
    getData: function(){
        axios.get(tools.url('/api/politicadevolucion')).then(({data}) =>{
            this.info = data;
        });
    },
  },
  mounted(){
    this.getData();
  }
}
</script>

